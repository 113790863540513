.sections {
    background: rgba(255, 255, 255, .5);
    box-shadow: "-8 -8 80px red";
    overflow: hidden;
    border-radius: 4%;
    padding: 8px;
    margin-bottom: 10px;
}

p {
    font-size: .8rem;
    line-height: .9rem;
    margin-bottom: 5px;
}

.section-num {
    z-index: 1;
    position: absolute;
    align-self: center;
    justify-self: center;
    font-size: 5rem;
}